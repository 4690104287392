(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .factory('StaticLookUp', StaticLookUp);

    StaticLookUp.$inject = ['$resource'];

    function StaticLookUp ($resource) {

    	 var resourceUrl =  'api/entity-owner-type';
        return $resource(resourceUrl,{}, {
        	'getAllHolidayType':{
        		 method: 'GET',
        		 url: 'api/get-holiday-types',
                 isArray: true,
                 transformResponse: function (data) {
                     if (data) {
                         data = angular.fromJson(data);
                     }
                     return data;
                 }
        	},
        
            'getEntityOwnerTypes': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getEntityOwnerTypesForOrganisationType': {
                method: 'GET',
                url: 'api/organisationType/:organisationTypeId/entity-owner-type',
                params: {organisationTypeId: '@organisationTypeId'},
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getEntityOwnerTypesForUserEntityOwnerType': {
                method: 'GET',
                url: 'api/user-entity-owner-type/:entityOwnerTypeId/entity-owner-type',
                params: {entityOwnerTypeId: '@entityOwnerTypeId'},
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getEntityOwnerTypeForShortName': {
                method: 'GET',
                url: 'api/entityOwnerType/:entityOwnerType/entity-owner-type',
                params: {entityOwnerType: '@entityOwnerType'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getStatesForCountry': {
            	method: 'GET',
                url: 'api/public/country/:countryId/states-for-country',
                isArray: true,
                params: {countryId: '@countryId'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getCustomerTypes': {
                method: 'GET',
                isArray: true,
                url: 'api/public/customer-type',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAllCustomerTypes': {
                method: 'GET',
                isArray: true,
                url: 'api/public/all-customer-types',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getConfigSettings': {
                method: 'GET',
                url: 'api/configuration-settings',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getConfigSettingsPreLogin': {
                method: 'GET',
                url: 'api/configuration-settings-pre-login',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getHomeBranchDetails': {
                method: 'GET',
                isArray: true,
                async: false,
                url: 'api/public/home-branch-details/:filterText',
                params: {filterText: '@filterText'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getEntity': {
                method: 'GET',
                url:'api/entity/:entityOwnerType/entityOwnerType',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getCurrency': {
            	
            	method: 'GET',
                url:'api/currency',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },'getCurrencyPair': {
            	
            	method: 'GET',
                url:'api/currency-pair',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },'getDayNumberList':{
        	   method: 'GET',
               url: 'api/day-number',
               isArray: true,
               transformResponse: function (data) {
                   if (data) {
                       data = angular.fromJson(data);
                   }
                   return data;
               }
           },
           'getWeekNumberList':{
        	   method: 'GET',
               url: 'api/week-number',
               isArray: true,
               transformResponse: function (data) {
                   if (data) {
                       data = angular.fromJson(data);
                   }
                   return data;
               }
           },
           'getMarkupTypes': {
                method: 'GET',
                isArray: true,
                url: 'api/markup-type',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getInstrumentType':{
            	isArray: true,
                url: 'api/instrument-type',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getLimitType':{
            	isArray: true,
                url: 'api/limit-type',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getOrderStatus':{
            	isArray: true,
                url: 'api/order-status',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getUserStatusExceptDelinked':{
                isArray: true,
                url: 'api/user-status-except-delinked',
                params: {selectedEntity: '@selectedEntity'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getSodWorkFlow' :{
            	isArray: true,
                url: 'api/sod-process-workflow',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getEodWorkFlow' :{
            	isArray: true,
                url: 'api/eod-process-workflow',
               /*transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }*/
            },
            'getErrorMessages' :{
            	isArray: true,
                url: 'api/error-messages',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getProcessErrorMessages' :{
            	isArray: true,
                url: 'api/process/error-messages',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getFinancialYear' :{
            	
                url: 'api/financial-year-details',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getCustomerChargesType':{
            	isArray: true,
                url: 'api/customer-charges-type',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getChargesType':{
            	isArray: true,
                url: 'api/charges-type',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getPastAndPresentFinancialYears':{
            	isArray: true,
                url: 'api/get-past-and-present-financial-years',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getTradesToBeSettledReportFilter':{
            	isArray: true,
                url: 'api/get-trades-to-be-settled-report-filter',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAlertTypes':{
            	isArray: true,
                url: 'api/get-alert-types',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getMarketInstrumentTypes':{
            	isArray: true,
                url: 'api/market-intrument-types',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getTenorsWithBrokenDate':{
                isArray: true,
                url: 'api/tenors-with-broken-date',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getTenorsForLookup':{
                isArray: true,
                url: 'api/tenors-lookup',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getEventTypeList':{
                isArray: true,
                url: 'api/event-type-list',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getTradeStatusList':{
                isArray: true,
                url: 'api/trade-status-list',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getLimitInstrumentGroupList':{
                isArray: true,
                url: 'api/limit-instrument-group-list',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getLimitReinstateTypeList':{
                isArray: true,
                url: 'api/limit-reinstate-type-list',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getLimitTenorTypeList':{
                isArray: true,
                url: 'api/limit-tenor-type-list',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getRoEdApplicabilityMethodList':{
                isArray: true,
                url: 'api/ro-ed-applicability-method-list',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getCustomerTypeWiseInputDetails':{
                isArray: true,
                url: 'api/public/customer-type-wise-input-details',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getVersionNo':{
                isArray: false,
                url: 'api/public/version-no',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAllVirtualDemoDetails':{
                method: 'GET',
                isArray: true,
                url: 'api/public/get-all-virtual-demo-details',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAllRestrictionReaons':{
                isArray: true,
                url: 'api/get-restriction-reasons',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getsourceOfIntro': {
                method: 'GET',
                isArray: true,
                url: 'api/public/get-source-of-intro',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getBankCategory': {
                method: 'GET',
                isArray: true,
                url: 'api/public/get-bank-category',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },

        });
    }
})();
