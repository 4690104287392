(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .service("workflowSharedService", function () {        	
        	this.viewFrom= 'customerregistration'
        })
        .controller('CustomerViewReportController', CustomerViewReportController);

    CustomerViewReportController.$inject = ['$scope', '$state', 'workflowSharedService','CustomerTradingBankDetails',  'GlobalValues','EntityPermissions','$filter', 'entity','ScreenLog','entityConstants'];

    function CustomerViewReportController($scope, $state, workflowSharedService, CustomerTradingBankDetails,  GlobalValues, EntityPermissions,$filter, entity,ScreenLog,entityConstants) {    
        var vm = this;
        vm.globalValues = GlobalValues;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        vm.getCustomerDetails=getCustomerDetails;
        
        vm.userType = vm.globalValues.getOrganisation().organisationType.shortName;
        vm.userEntityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
        vm.userBranch=vm.globalValues.getBranch();
        vm.screenLog = ScreenLog;
        vm.exportAllCustomerDetails = exportAllCustomerDetails;
        workflowSharedService.viewFrom = "customerregistration";
        
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMER_VIEW_REPORT;
        vm.modifyCustomerDetailsScreenName = vm.entityConstants.ENTITY_SHORT_NAME.MODIFY_CUSTOMER_DETAILS;
        vm.permitTradingBranchModification = EntityPermissions.hasPermission( vm.modifyCustomerDetailsScreenName+'.' +vm.entityConstants.ENTITY_ACTION_TYPE.EDIT);
		vm.clicked=false;
        
		vm.exportButtonEnabled = true;

        vm.editTradingBankDetails = editTradingBankDetails;
        logScreenAccess();
		vm.exportToFile = exportToFile
		vm.mimeType= '';  	 
        if(vm.userEntityOwnerType=='Branch'){
        	loadAll();
        }
        
        function getCustomerDetails()
        {   
        	// var filterObj = {}
            // filterObj.bankId=null;
            // filterObj.branchId=null;

        	var bankId=null;
            var branchId=null;
        	if(vm.userType=='CLEARCORP'){
        		if(vm.bank != null && vm.bank != undefined && vm.bank != '')
        			bankId=vm.bank.id;
        		if(vm.branch != null && vm.branch != undefined && vm.branch != '')
        			branchId=vm.branch.id;
        	}
        	if(vm.userType=='BANK' && vm.userEntityOwnerType!='Branch'){
        		bankId=vm.globalValues.getOrgId();
        		if(vm.branch != null && vm.branch != undefined && vm.branch != '')
        			branchId=vm.branch.id;
        	}
        	if(vm.userEntityOwnerType=='Branch'){
        		bankId=vm.globalValues.getOrgId();
        		branchId=vm.globalValues.getBranchId();
        	}
        	
        	CustomerTradingBankDetails.getCustomerDetailsForReport({"bankId":bankId,"branchId":branchId},onGetCustomerDetailsSuccess,onGetCustomerDetailsError);
			// return filterObj;
        }
        
        function exportAllCustomerDetails()
        {   
        	
        	var bankId=null;
            var branchId=null;
			vm.exportButtonEnabled = false;

      	  	var  mimeType = 'application/vnd.ms-excel';
      	  	var fileData = null;
      	  	var baseString = 'Customer_View_Report_'+ moment().format( 'DD_MMM_YYYY_HH_mm')+ ".xls";  
      	  CustomerTradingBankDetails.exportAllCustomerDetails(  function(result){
      			 
      			  fileData = result.data;
      			  if(fileData!=null && fileData!=undefined ){
	      			  var data = "";
	      			  console.log( "type" + typeof fileData); 
	      			  /*var link = document.createElement("a");  
	      			  var bytes = new Uint8Array( fileData );   // convert uint8rom arraybuffer
	      			  //data = btoa(String.fromCharCode.apply(null, bytes)); // get data string from binary array
	      			  //link.href = 'data:'+mimeType+';base64,'+ encodeURI(data);
	      			  var blob = new Blob([bytes], {type: mimeType});
				      link.href =  window.URL.createObjectURL(blob);
	      		   	  link.download = baseString;
	      		   	  document.body.appendChild(link);
	      		   	  link.click();
	      			  document.body.removeChild(link);*/
	      			  
	      			var downloadLink = document.createElement("a");
	      			var docExt = "xls";
	                var blob = new Blob([fileData], {type: 'application/vnd.ms-xls'});
	                downloadLink.href =  window.URL.createObjectURL(blob);
	                downloadLink.download = baseString;
	                var blob = new Blob([fileData], { type: "application/vnd.ms-excel;charset=utf-8" })
	                window.saveAs(blob, baseString);
      			  }
      		  });	
        }
        function onGetCustomerDetailsSuccess(result)
        {
        	vm.customerDetails=result;
        	vm.clicked=false;
        }
        
        
        function onGetCustomerDetailsError()
        {
        	vm.clicked=false;
        }
        function loadAll(){
        	getCustomerDetails();
        	
        }
        function logScreenAccess(){
           	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
           }
        function editTradingBankDetails(customerTradingBankDetails,$event){
        	 //workflowSharedService.viewFrom == 'customerViewReportEdit'
        	 ScreenLog.screenLog(vm.modifyCustomerDetailsScreenName,customerTradingBankDetails.id, vm.entityConstants.ENTITY_ACTION_TYPE.EDIT);
        	 $state.params = {id:customerTradingBankDetails.id}
    		 var state = 'modify-customer-details';
    		 $state.go(state,{id:customerTradingBankDetails.id});
        	//
        }
        
        vm.getAddress = function getAddress(address1, address2){
        	var address = '-';
        	if(address1!=null && address1!=undefined && address1!=''){
        		address = address1;
        	}
        	if(address2!=null && address2!=undefined && address2!=''){
        		address = address + ', ' + address2;
        	}
        	return address;
        }
        
        vm.getMobile = function getMobile(mobile, mobileCountryCode){
        	var mobileNo = '-';
        	if(mobile!=null && mobile!=undefined && mobile!=''){
        		mobileNo = mobile;
        		if(mobileCountryCode!=null && mobileCountryCode!=undefined && mobileCountryCode!=''){
        			mobileNo = mobileCountryCode + '-' + mobile;
        		}
        	}
        	return mobileNo;
        }

		//export csv and excel file 
		function exportToFile(fileType){
	 	   var bankId=null;
			var branchId=null;
			vm.exportButtonEnabled = false;
			if(vm.userType=='CLEARCORP'){
        		if(vm.bank != null && vm.bank != undefined && vm.bank != '')
        			bankId=vm.bank.id;
        		if(vm.branch != null && vm.branch != undefined && vm.branch != '')
        			branchId=vm.branch.id;
        	}
        	if(vm.userType=='BANK' && vm.userEntityOwnerType!='Branch'){
        		bankId=vm.globalValues.getOrgId();
        		if(vm.branch != null && vm.branch != undefined && vm.branch != '')
        			branchId=vm.branch.id;
        	}
        	if(vm.userEntityOwnerType=='Branch'){
        		bankId=vm.globalValues.getOrgId();
        		branchId=vm.globalValues.getBranchId();
			}

			vm.baseString =  'Customer_View_Report_'+ moment().format( 'DD_MMM_YYYY_HH_mm');
			
			if( vm.customerDetails !=null &&  vm.customerDetails!=undefined){
				if(fileType == 'csv'){
					vm.mimeType = 'text/csv'
					vm.baseString = vm.baseString+ ".csv";
					
					CustomerTradingBankDetails.exportCSVData({"bankId":bankId,"branchId":branchId},onGenerateSuccess);
				}else if(fileType == 'excel'){
					vm.mimeType = 'application/vnd.ms-excel';
					vm.baseString = vm.baseString+ ".xls"; 
					CustomerTradingBankDetails.exportExcelData({"bankId":bankId,"branchId":branchId},onGenerateSuccess);
				}
			}
		}
      
		/* generate  excel and csv file  */
		function onGenerateSuccess(result){
        	
			var fileData = result.data;
			vm.exportButtonEnabled = true;
			 
			 var IEfile= new Blob([result.data], {type: vm.mimeType});
				if(window.navigator.msSaveOrOpenBlob) {
				   window.navigator.msSaveOrOpenBlob(IEfile, vm.baseString );
				}
				else
					{
					 var link = document.createElement("a");   
					 var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
					 
					 
					 var blob = new Blob([bytes], {type: vm.mimeType});
					 link.href =  window.URL.createObjectURL(blob);
					 
				  
					 
						link.download = vm.baseString;
						document.body.appendChild(link);
						link.click();
					 document.body.removeChild(link);
					}
		}
       
     }
	
})();
