(function() {
	'use strict';
	angular
		.module('gatewayApp')
		.factory('AppLookup', AppLookup);

	AppLookup.$inject = [ 'GlobalValues', '$rootScope', '$sessionStorage', 'Role', 'Branch','OrganisationType',
	'CustomerUserType','GlobalOrganisationTypeRole','BankRegistration', 'Country', 'StaticLookUp', 
	'CustomerRegistration', 'User', 'CustomerBankRelationshipReport', 'SecurityPolicy', 'ApprovalPolicy'];

	function AppLookup(GlobalValues, $rootScope, $sessionStorage, Role, Branch,OrganisationType,
		CustomerUserType,GlobalOrganisationTypeRole,BankRegistration, Country, StaticLookUp, 
		CustomerRegistration, User, CustomerBankRelationshipReport, SecurityPolicy, ApprovalPolicy) {

		var  onSuccess = function onSuccess(data) {
			$rootScope.$emit('gatewayApp:loadLookupComplete', data);
		}

		var onFailure = function onFailure() {
		}
		
		return {
			getLookupValue : function(lookupName, filterValue) {
				if ($sessionStorage.isAuth == true) {
					var returnValue = null;
					var filters = filterValue.split("~~");

					if (lookupName == "currency") {
						//returnValue = Currency.query();
						returnValue = StaticLookUp.getCurrency();
					} else if(lookupName == "organisationType"){
						returnValue = OrganisationType.query();
					} else if(lookupName == "customerUserType"){
						returnValue = CustomerUserType.query();
					} else if (lookupName == "country") {
						returnValue = Country.query();
					} else if (lookupName == "bank") {
						returnValue = BankRegistration.getBankLookup();
					} else if (lookupName == "bankWithStatus") {
						returnValue = BankRegistration.getBankLookupWithStatus();
					} else if (lookupName == "bankDetails") {
						returnValue = CustomerRegistration.getBankDetails();
					} else if (lookupName == "homeBranch") {
						if(filters[0] != "" && filters[0] != undefined){
							returnValue = StaticLookUp.getHomeBranchDetails({filterText: filters[0]}, onGetSuccess, onGetFailure);
						}
					} 
					/*else if (lookupName == "branch") {
						returnValue = Branch.query();
					}*/ 
					else if (lookupName == "state") {
						if(filters[0] != "" && filters[0] != undefined){
							returnValue = StaticLookUp.getStatesForCountry({countryId: filters[0]});	
						}
					} else if (lookupName == "entityOwnerType") {
						returnValue = StaticLookUp.getEntityOwnerTypes();
					} else if (lookupName == "entityOwnerTypeByOrganisationType") {
						returnValue = StaticLookUp.getEntityOwnerTypesForOrganisationType({ "organisationTypeId": GlobalValues.getOrganisation().organisationType.id });
			        } else if (lookupName == "entityOwnerTypeByUserEntityOwnerType") {
						returnValue = StaticLookUp.getEntityOwnerTypesForUserEntityOwnerType({ "entityOwnerTypeId": GlobalValues.getEntityOwnerType().id });
			        } else if (lookupName == "clearanceOrganisation") {
						returnValue = Corporate.getAllClearanceOrganisations();
					} else if (lookupName == "organisation") {
						returnValue = [];
						returnValue.push(GlobalValues.getOrganisation());
					} else if (lookupName == "Role") {
						if (filters[0] != "") {
							returnValue = Role.rolesAllForOrganisation({
								orgId : filters[0]
							});
						}
					} else if (lookupName == "theme") {
						if (filters[0] != "") {
							returnValue = SecurityPolicy.themesForOrganisation({
								orgId : filters[0]
							});
						}
					} else if (lookupName == "policy") {
						if (filters[0] != "") {
							returnValue = ApprovalPolicy.getAllApprovalPolicy({
								orgId : filters[0]
							});
						}
					} else if (lookupName == "actionsForOrgAndPolicy") {
						if (filters[0] != "" && filters[1] != "") {
							returnValue = ApprovalPolicy.actionsForOrgAndPolicy({
								orgId : filters[0], policyId : filters[1]
							});
						}
					}  else if (lookupName == "actionsForOrgAndTheme") {
						if (filters[0] != "" && filters[1] != "") {
							returnValue = SecurityPolicy.actionsForOrgAndTheme({
								orgId : filters[0], themeId : filters[1]
							});
						}
					}  else if (lookupName == "GlobalRole") {
						if (filters[0] != "") {
							if(filters[1]==undefined || filters[1]==""){
								filters[1] = 0;
							}
							returnValue = GlobalOrganisationTypeRole.rolesAllForOrganisationType({
								orgTypeId : filters[0],
								custUserTypeId : filters[1]
							});
						}
					} else if (lookupName == "organisationForApprovalPolicy") {
						if (filters[0] != "") {
							filters[0] = angular.fromJson(filters[0]);
							returnValue = [];
							returnValue
									.push(GlobalValues.getUserOrganisation());
							if (GlobalValues.getUserOrganisation().id != filters[0].id) {
								returnValue.push(filters[0]);
							}
							
						}
					} else if (lookupName == "organisationTypesForApprovalPolicy") {
						if (filters[0] != "") {
							filters[0] = angular.fromJson(filters[0]);
							returnValue = [];
							returnValue.push(filters[0]);
						}
					}else if(lookupName == "branch"){
						if (filters[0] != "") {
							returnValue = Branch.getAllBranchesLookupForBank({
								bankId : filters[0]
							});
						}
					}else if(lookupName == "branchWithStatus"){
						if (filters[0] != "") {
							returnValue = Branch.getAllBranchesLookupWithStatusForBank({
								bankId : filters[0]
							});
						}
					}else if(lookupName == "activeBranch"){
						if (filters[0] != "") {
							returnValue = Branch.getAllActiveBranchesLookupForBank({
								bankId : filters[0]
							});
						}
					}
					else if (lookupName == "allBranches") {
						returnValue = Branch.getBranchLookup();
					}
					else if (lookupName == "customersForBranch") {
						if(filters[0]!=""){
							returnValue = CustomerRegistration.getAllCustomersForBranch({"branchId": filters[0]});
						}
					}
					else if(lookupName == "branchesForBank"){
						if (filters[0] != "") {
							returnValue = Branch.getAllBranchesLookupForBank({ "bankId": filters[0] }, onSuccess, onFailure);
						}else if(GlobalValues.getOrganisation().organisationType.shortName=="BANK"){
							returnValue = Branch.getAllBranchesLookupForBank({ "bankId": GlobalValues.getOrgId() }, onSuccess, onFailure);
						}
					}
					else if(lookupName == "activeBranchesForBank"){
						if (filters[0] != "") {
							returnValue = Branch.getAllActiveBranchesLookupForBank({ "bankId": filters[0] }, onSuccess, onFailure);
						}else if(GlobalValues.getOrganisation().organisationType.shortName=="BANK"){
							returnValue = Branch.getAllActiveBranchesLookupForBank({ "bankId": GlobalValues.getOrgId() }, onSuccess, onFailure);
						}
					}
					else if(lookupName == "customerType"){
						returnValue = StaticLookUp.getCustomerTypes();
					} else if(lookupName == "AllCustomerTypes"){
						returnValue = StaticLookUp.getAllCustomerTypes();
					} else if(lookupName == "customer"){
						if(filters[0]!="" && filters[1]!=""){
							returnValue = CustomerRegistration.getCustomersForBranch({"bankId": filters[0], "branchId":filters[1],"customerType":filters[2]});
						}
						else if(filters[0]=="" && filters[1]!=""){
							returnValue = CustomerRegistration.getCustomersForBranch({"bankId": GlobalValues.getOrgId(), "branchId":filters[1],"customerType":filters[2]});
						}
						else if(filters[0]=="" && filters[1]=="" && filters[2] != "" && GlobalValues.getBranch()!=null){
							returnValue = CustomerRegistration.getCustomersForBranch({"bankId": GlobalValues.getOrgId(), "branchId":GlobalValues.getBranch().id,"customerType":filters[2]});
						}
						else if(filters[0]!="" && filters[1]=="" && filters[2] != "" && GlobalValues.getBranch()==null){
							returnValue = CustomerRegistration.getCustomersForBranch({"bankId": filters[0], "branchId":0,"customerType":filters[2]});
						}
					}else if(lookupName == "customerByEntityOwnerTypeShortName"){
						if(filters[0]!="" && filters[1]!=""){
							returnValue = CustomerRegistration.customerByEntityOwnerTypeShortName({"bankId": filters[0], "branchId":filters[1],"customerType":filters[2]});
						}
						else if(filters[0]=="" && filters[1]!=""){
							returnValue = CustomerRegistration.customerByEntityOwnerTypeShortName({"bankId": GlobalValues.getOrgId(), "branchId":filters[1],"customerType":filters[2]});
						}
						else if(filters[0]=="" && filters[1]=="" && filters[2] != "" && GlobalValues.getBranch()!=null){
							returnValue = CustomerRegistration.customerByEntityOwnerTypeShortName({"bankId": GlobalValues.getOrgId(), "branchId":GlobalValues.getBranch().id,"customerType":filters[2]});
						}
					} else if(lookupName == "user"){
						var userEntityOwnerType = GlobalValues.getEntityOwnerType().shortName;
						if(filters[0]!=""){
							if (filters[0]==userEntityOwnerType && userEntityOwnerType!="Branch"){
								returnValue = User.getUsersByOrganisationAndEntityOwnerType({"orgId": GlobalValues.getOrgId(), entityOwnerType: userEntityOwnerType});
							}else if (filters[0]==userEntityOwnerType && userEntityOwnerType=="Branch"){
								returnValue = User.getUsersForBranch({"branchId": GlobalValues.getBranch().id});
							}else if(userEntityOwnerType=="Clearcorp" && filters[0]=="Bank" && filters[1]!=""){
								returnValue = User.getUsersByOrganisationAndEntityOwnerType({"orgId": filters[1], entityOwnerType: 'Bank'});
							}else if((userEntityOwnerType=="Clearcorp" || userEntityOwnerType=="Bank") && filters[0]=="Branch" && filters[2]!=""){
								returnValue = User.getUsersForBranch({"branchId": filters[2]});
							}else if((userEntityOwnerType=="Clearcorp" || userEntityOwnerType=="Bank" || userEntityOwnerType=="Branch") && (filters[0]=="MultiUserCustomer" || filters[0]=="SingleUserCustomer") && filters[3]!=""){
								returnValue = User.getUsersByOrganisationAndEntityOwnerType({"orgId": filters[3], entityOwnerType: filters[0]});
							}
						}else if(userEntityOwnerType=="MultiUserCustomer" || userEntityOwnerType=="SingleUserCustomer"){
							returnValue = User.getUsersByOrganisationAndEntityOwnerType({"orgId": GlobalValues.getOrgId(), entityOwnerType: userEntityOwnerType});
						}
					}else if(lookupName == "allUsers"){
						var userEntityOwnerType = GlobalValues.getEntityOwnerType().shortName;
						if(filters[0]!=""){
							if (filters[0]==userEntityOwnerType && userEntityOwnerType!="Branch"){
								returnValue = User.getAllUsersByOrganisationAndEntityOwnerType({"orgId": GlobalValues.getOrgId(), entityOwnerType: userEntityOwnerType});
							}else if (filters[0]==userEntityOwnerType && userEntityOwnerType=="Branch"){
								returnValue = User.getAllUsersForBranch({"bankId":GlobalValues.getBankId(),"branchId": GlobalValues.getBranch().id});
							}else if(userEntityOwnerType=="Clearcorp" && filters[0]=="Bank" && filters[1]!=""){
								returnValue = User.getAllUsersByOrganisationAndEntityOwnerType({"orgId": filters[1], entityOwnerType: 'Bank'});
							}else if((userEntityOwnerType=="Clearcorp" || userEntityOwnerType=="Bank") && filters[0]=="Branch" && filters[2]!=""){
								returnValue = User.getAllUsersForBranch({"bankId":GlobalValues.getOrgId(),"branchId": filters[2]});
							}else if((userEntityOwnerType=="Clearcorp" || userEntityOwnerType=="Bank" || userEntityOwnerType=="Branch") && (filters[0]=="MultiUserCustomer" || filters[0]=="SingleUserCustomer") && filters[3]!=""){
								returnValue = User.getAllUsersByOrganisationAndEntityOwnerType({"orgId": filters[3], entityOwnerType: filters[0]});
							}
						}else if(userEntityOwnerType=="MultiUserCustomer" || userEntityOwnerType=="SingleUserCustomer"){
							returnValue = User.getAllUsersByOrganisationAndEntityOwnerType({"orgId": GlobalValues.getOrgId(), entityOwnerType: userEntityOwnerType});
						}else{
							returnValue=User.getAllUsersForOrganisation({"orgId": filters[3]});
						}
					}
					else if(lookupName == "userForRestriciton"){
						var userEntityOwnerType = GlobalValues.getEntityOwnerType().shortName;
						if(filters[0]!=""){
							if (filters[0]==userEntityOwnerType && userEntityOwnerType!="Branch"){
								returnValue = User.getUsersByOrganisationAndEntityOwnerTypeForRestriciton({"orgId": GlobalValues.getOrgId(), entityOwnerType: userEntityOwnerType});
							}else if (filters[0]==userEntityOwnerType && userEntityOwnerType=="Branch"){
								returnValue = User.getUsersForBranchRestriction({"branchId": GlobalValues.getBranch().id});
							}else if(userEntityOwnerType=="Clearcorp" && filters[0]=="Bank" && filters[1]!=""){
								returnValue = User.getUsersByOrganisationAndEntityOwnerTypeForRestriciton({"orgId": filters[1], entityOwnerType: 'Bank'});
							}else if((userEntityOwnerType=="Clearcorp" || userEntityOwnerType=="Bank") && filters[0]=="Branch" && filters[2]!=""){
								returnValue = User.getUsersForBranchRestriction({"branchId": filters[2]});
							}else if((userEntityOwnerType=="Clearcorp" || userEntityOwnerType=="Bank" || userEntityOwnerType=="Branch") && (filters[0]=="MultiUserCustomer" || filters[0]=="SingleUserCustomer") && filters[3]!=""){
								returnValue = User.getUsersByOrganisationAndEntityOwnerTypeForRestriciton({"orgId": filters[3], entityOwnerType: filters[0]});
							}
						}else if(userEntityOwnerType=="MultiUserCustomer" || userEntityOwnerType=="SingleUserCustomer"){
							returnValue = User.getUsersByOrganisationAndEntityOwnerTypeForRestriciton({"orgId": GlobalValues.getOrgId(), entityOwnerType: userEntityOwnerType});
						}else{
							returnValue=User.getUsersForOrganisationForRestriction({"orgId": filters[3]});
						}
					}else if(lookupName == "userForTurnOverReport"){
						var userEntityOwnerType = GlobalValues.getEntityOwnerType().shortName;
						if(filters[0]!=""){
							if (filters[0]==userEntityOwnerType && userEntityOwnerType!="Branch"){
								returnValue = User.getUsersByOrganisationAndEntityOwnerTypeTurnOverReport({"orgId": GlobalValues.getOrgId(), entityOwnerType: userEntityOwnerType});
							}else if (filters[0]==userEntityOwnerType && userEntityOwnerType=="Branch"){
								returnValue = User.getUsersForBranchTurnOverReport({"bankId":GlobalValues.getBankId(),"branchId": GlobalValues.getBranch().id});
							}else if(userEntityOwnerType=="Clearcorp" && filters[0]=="Bank" && filters[1]!=""){
								returnValue = User.getUsersByOrganisationAndEntityOwnerTypeTurnOverReport({"orgId": filters[1], entityOwnerType: 'Bank'});
							}else if((userEntityOwnerType=="Clearcorp" || userEntityOwnerType=="Bank") && filters[0]=="Branch" && filters[2]!=""){
								returnValue = User.getUsersForBranchTurnOverReport({"bankId":GlobalValues.getOrgId(),"branchId": filters[2]});
							}else if((userEntityOwnerType=="Clearcorp" || userEntityOwnerType=="Bank" || userEntityOwnerType=="Branch") && (filters[0]=="MultiUserCustomer" || filters[0]=="SingleUserCustomer") && filters[3]!=""){
								returnValue = User.getUsersByOrganisationAndEntityOwnerTypeTurnOverReport({"orgId": filters[3], entityOwnerType: filters[0]});
							}
						}else if(userEntityOwnerType=="MultiUserCustomer" || userEntityOwnerType=="SingleUserCustomer"){
							returnValue = User.getUsersByOrganisationAndEntityOwnerTypeTurnOverReport({"orgId": GlobalValues.getOrgId(), entityOwnerType: userEntityOwnerType});
						}else{
							returnValue=User.getUsersForOrganisationTurnOverReport({"orgId": filters[3]});
						}
					}
					else if(lookupName == "entity"){
							if(filters[0]!="")
								returnValue =StaticLookUp.getEntity({"entityOwnerType":filters[0]});
					}
					else if(lookupName == "tradingBank"){
						if (filters[0] != "") {
							returnValue =CustomerBankRelationshipReport.getTradingBanksForCustomer({
								customerId : filters[0]
							});
						}
					}
					else if(lookupName == "organisationTypeForGlobalApprovalPolicy"){
						returnValue = OrganisationType.organisationTypesForGlobalApprovalPolicy({"orgId":GlobalValues.getOrgId()});
					}
					else if(lookupName == "AllCustomers"){
						returnValue = CustomerRegistration.query();
					}
					else if(lookupName == "usersForOrganisation"){
						var userEntityOwnerType = GlobalValues.getEntityOwnerType().shortName;
						if(userEntityOwnerType=="Branch"){
							returnValue = User.getUsersForBranch({"branchId": GlobalValues.getBranch().id});	
						}
						else if(userEntityOwnerType=="MultiUserCustomer" || userEntityOwnerType=="SingleUserCustomer"){
							returnValue = User.getUsersByOrganisationAndEntityOwnerType({"orgId": GlobalValues.getOrgId(), entityOwnerType: userEntityOwnerType});
						}
						else{
							returnValue=User.getUsersForOrganisation({"orgId":GlobalValues.getOrgId()});
						}
					
					}
					else if(lookupName == "usersForGivenOrganisation"){
						if (filters[0] != "") {
							returnValue=User.getUsersExceptDelinkedForOrganisation({"orgId":filters[0]});
						}
					}
					else if(lookupName == "usersForGivenBranch"){
						if (filters[0] != "") {
							returnValue = User.getUsersExceptDelinkedForBranch({"branchId": filters[0]});
						}
					}
					else if(lookupName == "nonDelinkedUsersForGivenBranch"){
						if (filters[0] != "") {
							returnValue = User.getNonDelinkedUsersForBranch({"bankId":GlobalValues.getOrgId(),"branchId": filters[0]});
						}
					}
					else if(lookupName == "allUsersForOrganisation"){
						var userEntityOwnerType = GlobalValues.getEntityOwnerType().shortName;
						if(userEntityOwnerType=="Branch"){
							returnValue = User.getAllUsersForBranch({"bankId":GlobalValues.getBankId(),"branchId": GlobalValues.getBranch().id});	
						}
						else if(userEntityOwnerType=="MultiUserCustomer" || userEntityOwnerType=="SingleUserCustomer"){
							returnValue = User.getAllUsersByOrganisationAndEntityOwnerType({"orgId": GlobalValues.getOrgId(), entityOwnerType: userEntityOwnerType});
						}
						else{
							returnValue=User.getAllUsersForOrganisation({"orgId":GlobalValues.getOrgId()});
						}
					
					}
					else if(lookupName == "usersByOrganisationForRestriction"){
						var userEntityOwnerType = GlobalValues.getEntityOwnerType().shortName;
						if(userEntityOwnerType=="Branch"){
							returnValue = User.getUsersForBranchRestriction({"branchId": GlobalValues.getBranch().id});	
						}
						else if(userEntityOwnerType=="MultiUserCustomer" || userEntityOwnerType=="SingleUserCustomer"){
							returnValue = User.getUsersByOrganisationAndEntityOwnerTypeForRestriciton({"orgId": GlobalValues.getOrgId(), entityOwnerType: userEntityOwnerType});
						}
						else if(userEntityOwnerType=="Bank"){
							returnValue = User.getUsersByOrganisationAndEntityOwnerTypeForRestriciton({"orgId": GlobalValues.getOrgId(), entityOwnerType: userEntityOwnerType});
						}
						else{
							returnValue=User.getUsersForOrganisationForRestriction({"orgId":GlobalValues.getOrgId()});
						}
					
					}
					else if (lookupName == "allTypeCustomers"){
						if(filters[0]!="" && filters[1]!=""){
							returnValue = CustomerRegistration.getSingleAndMultiCustomersForBranch({"bankId": filters[0], "branchId":filters[1]});
						}/*else if(filters[0]!="" && filters[1]==""){
							returnValue = CustomerRegistration.getCustomersForBank({"bankId": filters[0]});
						}*/
					}else if (lookupName == "nonPropCustomersForBranch"){
						if(filters[0]!="" && filters[1]!=""){
							returnValue = CustomerRegistration.nonPropCustomersForBranch({"bankId": filters[0], "branchId":filters[1]});
						}
					}
					else if (lookupName == "allNonPropCustomersForBank"){
						if(filters[0]!="" && filters[1]!=""){
							returnValue = CustomerRegistration.getAllNonPropCustomersForBank({"bankId": filters[0], "customerUserTypeId":filters[1]});
						}
					}
					else if (lookupName == "customersForBank"){
						if(filters[0]!=""){
							returnValue = CustomerRegistration.getCustomersForBank({"bankId": filters[0]});
						}
					}
					
					else if (lookupName == "allTypeAndStatusCustomers"){
						if(filters[0]!="" && filters[1]!=""){
							returnValue = CustomerRegistration.getAllStatusSingleAndMultiCustomersForBranch({"bankId": filters[0], "branchId":filters[1]});
						}/*else if(filters[0]!="" && filters[1]==""){
							returnValue = CustomerRegistration.getCustomersForBank({"bankId": filters[0]});
						}*/
					}
					else if(lookupName == "allTypeCustomersForLimits"){
						var userEntityOwnerType = GlobalValues.getEntityOwnerType().shortName;
						if(userEntityOwnerType=="MultiUserCustomer" || userEntityOwnerType=="SingleUserCustomer"){
							if(filters[0]!="" && filters[1]!=""){
								var customerId = GlobalValues.getOrgId();
								returnValue = CustomerRegistration.getCustomersForBranchForLimits({"bankId": filters[0], "branchId":filters[1],"customerId":customerId});
							}
						}else{
							if(filters[0]!="" && filters[1]!=""){
								var customerId = 0;
								returnValue = CustomerRegistration.getCustomersForBranchForLimits({"bankId": filters[0], "branchId":filters[1],"customerId":customerId});
							}
						}
						
					}
					else if(lookupName == "validStatusBranchesForBank"){
						if (filters[0] != "") {
							var customerId = GlobalValues.getOrgId();
							returnValue = Branch.getValidBranchesForBank({ "bankId": filters[0] ,"customerId":customerId}, onSuccess, onFailure);
						}else if(GlobalValues.getOrganisation().organisationType.shortName=="BANK"){
							var customerId = 0;
							returnValue = Branch.getValidBranchesForBank({ "bankId": GlobalValues.getOrgId(),"customerId":customerId }, onSuccess, onFailure);
						}
					}else if(lookupName == "validBranchDetailsForBank"){
						if (filters[0] != "") {
							returnValue = Branch.getValidBranchDetailsForBank({ "bankId": filters[0] }, onSuccess, onFailure);
						}else if(GlobalValues.getOrganisation()!=null && GlobalValues.getOrganisation()!= undefined && GlobalValues.getOrganisation().organisationType.shortName=="BANK"){
							returnValue = Branch.getValidBranchDetailsForBank({ "bankId": GlobalValues.getOrgId() }, onSuccess, onFailure);
						}
					}else if(lookupName == "validBranchesForBank"){
						if (filters[0] != "") {
							returnValue = Branch.getValidBranchDetailsForBank({ "bankId": filters[0] }, onSuccess, onFailure);
						}else if(GlobalValues.getOrganisation()!=null && GlobalValues.getOrganisation()!= undefined && GlobalValues.getOrganisation().organisationType.shortName=="BANK"){
							returnValue = Branch.getValidBranchDetailsForBank({ "bankId": GlobalValues.getOrgId() }, onSuccess, onFailure);
						}
					}else if(lookupName == "validStatusBank"){
						returnValue = BankRegistration.getValidBankWithValidStatus();
					}
				else if(lookupName == "dayNumber"){
	    				returnValue = StaticLookUp.getDayNumberList();
	    			}
	    			else if(lookupName == "weekNumber"){
	    				returnValue = StaticLookUp.getWeekNumberList();
	    			}
					
	    			else if(lookupName == "instrument"){
	    				returnValue = StaticLookUp.getInstrumentType();
	    			}
					
	    			else if (lookupName == "orderStatus"){
	    				returnValue = StaticLookUp.getOrderStatus();
	    			}
	    			else if (lookupName == "userStatusExceptDelinked"){
	    				returnValue = StaticLookUp.getUserStatusExceptDelinked();
	    			}
	    			else if(lookupName == "customerChargesType"){
	    				returnValue = StaticLookUp.getCustomerChargesType();
	    			}
	    			else if(lookupName == "multiUserCustomerUserType"){
	    				returnValue = CustomerUserType.getWithoutSingleUser();
	    			}
	    			else if (lookupName == "customersForBankNonProp"){
						if(filters[0]!=""){
							returnValue = CustomerRegistration.getCustomersForBankNonProp({"bankId": filters[0]});
						}
					}
	    			else if (lookupName == "allTypeCustomersNonProp"){
	    				if(filters[0]!="" && filters[1]!=""){
							returnValue = CustomerRegistration.getSingleAndMultiCustomersForBranchNonProp({"bankId": filters[0], "branchId":filters[1]});
						}
					}
	    			else if (lookupName == "AllCustomersNonProp"){
	    				
							returnValue = CustomerRegistration.getAllNonPropAllCustomer();
						
					}
	    			else if (lookupName == "allNonPropCustomers"){
	    				if(filters[0]!=""){
							returnValue = CustomerRegistration.getAllNonPropCustomersForLookup({"customerUserTypeId":filters[0]});
	    				}
					}
	    			else if(lookupName == "tradingBankAllStatus"){
						if (filters[0] != "") {
							returnValue =CustomerBankRelationshipReport.getTradingBanksAllStatusForCustomer({
								customerId : filters[0]
							});
						}
					}else if(lookupName == 'nonTerminatedBanks'){
						
						returnValue = BankRegistration.getNonTerminatedBankList();
					}
					else if(lookupName == "allNonTerminatedCustomers"){
						if(filters[0]!="" && filters[1]!=""){
							returnValue = CustomerBankRelationshipReport.getAllNonTerminatedCustomers({"bankId": filters[0], "branchId":filters[1],"customerType":filters[2]});
						}
						
					}
					else if(lookupName == "nonPropNonTerminatedCustomers"){
						if(filters[0]!="" && filters[1]!=""){
							returnValue = CustomerBankRelationshipReport.getAllNonPropNonTerminatedCustomers({"bankId": filters[0], "branchId":filters[1],"customerType":filters[2]});
						}
						
					}
					else if(lookupName == "allNonTerminatedNonSuspendedCustomers"){
						if(filters[0]!="" && filters[1]!=""){
							returnValue = CustomerBankRelationshipReport.getAllNonTerminatedNonSuspendedCustomers({"bankId": filters[0], "branchId":filters[1],"customerType":filters[2]});
						}
						
					}
					else if(lookupName == "marketInstruments"){
	    				returnValue = StaticLookUp.getMarketInstrumentTypes();
	    			}
	    			else if(lookupName == "tenorsWithBrokenDate"){
						returnValue = StaticLookUp.getTenorsWithBrokenDate();		    			}

					else if(lookupName == "bankCategory"){
							returnValue = StaticLookUp.getBankCategory();
					}


					return returnValue;

				}
			

				function onGetSuccess(data){
					$rootScope.$emit('gatewayApp:loadLookupComplete', data);
					returnValue = data;
				}
				function onGetFailure(){
				}
			}
		};

	}

})();
