(function() {
	'use strict';

	angular.module('gatewayApp').service("ImportFile", ImportFile);

	ImportFile.$inject = [ 'ImportFileService', 'MessageBox', '$state', '$rootScope','GlobalValues'];

	function ImportFile(ImportFileService, MessageBox, $state, $rootScope,GlobalValues) {

		var corp = null;
		var uploadTypeName = null;
		var progressInstance = null;
		var service = {
			importFile : importFile,
			generateTemplate : generateTemplate,
			exportException : exportException
		};
		return service;

		function importFile(uploadType, fileContent) {
			if(uploadType == 'BankImport'){
				if(fileContent.name != 'BankImport.csv'){
					MessageBox.showMessageBox("Alert", "Invalid File Name", "Ok");
				}else {
					var fd = new FormData();
		        	fd.append("file", fileContent);
		        	console.log(fileContent);
		        	console.log(fd);
					uploadTypeName = uploadType;
					ImportFileService.importFile({"uploadType" : uploadType}, fd, onImportSuccess, onImportFailure);	
				}	
			}
			
			if(uploadType == 'BranchImport'){
				if(fileContent.name != 'BranchImport.csv'){
					MessageBox.showMessageBox("Alert", "Invalid File Name", "Ok");
				}else {
					var fd = new FormData();
		        	fd.append("file", fileContent);
		        	console.log(fileContent);
		        	console.log(fd);
					uploadTypeName = uploadType;
					ImportFileService.importFile({"uploadType" : uploadType}, fd, onImportSuccess, onImportFailure);	
				}	
			}
			
			if(uploadType == 'MarkupImport'){
				if(fileContent.name != 'MarkupImport.csv'){
					MessageBox.showMessageBox("Alert", "Invalid File Name", "Ok");
				}else {
					var fd = new FormData();
		        	fd.append("file", fileContent);
		        	console.log(fileContent);
		        	console.log(fd);
					uploadTypeName = uploadType;
					ImportFileService.importFile({"uploadType" : uploadType}, fd, onImportSuccess, onImportFailure);	
				}	
			}
			if(uploadType == 'LimitSettingsImport'){
				if(fileContent.name != 'LimitSettingsImport.csv'){
					MessageBox.showMessageBox("Alert", "Invalid File Name", "Ok");
				}else if(fileContent.size == 0){
					 MessageBox.showMessageBox("Alert", "Invalid File", "Ok");    
				}else {
					var fd = new FormData();
		        	fd.append("file", fileContent);
		        	console.log(fileContent);
		        	console.log(fd);
					uploadTypeName = uploadType;
					ImportFileService.orderImport({"uploadType" : uploadType}, fd, onImportSuccess, onImportFailure);	
				}	
			}
			if(uploadType == 'AMCImport'){
				if(fileContent.name != 'AMCImport.csv'){
					MessageBox.showMessageBox("Alert", "Invalid File Name", "Ok");
				}else {
					var fd = new FormData();
		        	fd.append("file", fileContent);
		        	console.log(fileContent);
		        	console.log(fd);
					uploadTypeName = uploadType;
					ImportFileService.importFile({"uploadType" : uploadType}, fd, onImportSuccess, onImportFailure);	
				}	
			}
			if(uploadType == 'OneTimeRegistrationChargesImport'){
				if(fileContent.name != 'OneTimeRegistrationChargesImport.csv'){
					MessageBox.showMessageBox("Alert", "Invalid File Name", "Ok");
				}else {
					var fd = new FormData();
		        	fd.append("file", fileContent);
		        	console.log(fileContent);
		        	console.log(fd);
					uploadTypeName = uploadType;
					ImportFileService.importFile({"uploadType" : uploadType}, fd, onImportSuccess, onImportFailure);	
				}	
			}
			if(uploadType == 'GSTSettingsImport'){
				if(fileContent.name != 'GSTSettingsImport.csv'){
					MessageBox.showMessageBox("Alert", "Invalid File Name", "Ok");
				}else {
					var fd = new FormData();
		        	fd.append("file", fileContent);
		        	console.log(fileContent);
		        	console.log(fd);
					uploadTypeName = uploadType;
					ImportFileService.importFile({"uploadType" : uploadType}, fd, onImportSuccess, onImportFailure);	
				}	
			}
			
			if(uploadType == 'TransactionChargesImport'){
				if(fileContent.name != 'TransactionChargesImport.csv'){
					MessageBox.showMessageBox("Alert", "Invalid File Name", "Ok");
				}else {
					var fd = new FormData();
		        	fd.append("file", fileContent);
		        	console.log(fileContent);
		        	console.log(fd);
					uploadTypeName = uploadType;
					ImportFileService.importFile({"uploadType" : uploadType}, fd, onImportSuccess, onImportFailure);	
				}	
			}

			if(uploadType == 'HomeBranchImport'){
				if(fileContent.name != 'homebranchupload.xls' && fileContent.name != 'homebranchupload.xlsx'  ){
					MessageBox.showMessageBox("Alert", "Invalid File Name", "Ok");
				}else {
					$rootScope.homebranchUploadInProgress = true;
					progressInstance = MessageBox
						.showMessageBox(
								"Alert",
								"Upload is in progress... Please wait",
								"autocloseImport");
					var fd = new FormData();
		        	fd.append("file", fileContent);
		        	console.log(fileContent);
		        	console.log(fd);
					uploadTypeName = uploadType;
					ImportFileService.importFile({"uploadType" : uploadType}, fd, onImportSuccess, onImportFailure);	
				}	
			}
			if(uploadType == 'SwapQuotesImport'){
				if(fileContent.name != 'SwapQuotesImport.csv'){
					MessageBox.showMessageBox("Alert", "Invalid File Name", "Ok");
				}else {
					var fd = new FormData();
		        	fd.append("file", fileContent);
		        	console.log(fileContent);
		        	console.log(fd);
					uploadTypeName = uploadType;
					ImportFileService.importFile({"uploadType" : uploadType}, fd, onImportSuccess, onImportFailure);	
				}	
			}
			
		}
		
		

		function generateTemplate(uploadType) {

			if(uploadType == 'BankImport'){
                var csvstring = 'Bank Name,CCIL Membership ID,Bank Member No,NG Code,Date Of Joining (dd-MMM-yyyy),Permissible Users,Legal Name,Trade Name,Primary Contact Name,Primary Email,Primary Mobile Country Code,Primary Mobile,Address Line1,Address Line2,City,Postal Code,GSTIN,Country,State,Bank PAN,Bank Category,User1 First Name,User1 Middle Name,User1 Last Name,User1 Gender (M/F/O),User1 DOB (dd-MMM-yyyy),User1 Login Name,User1 Email,User1 Mobile Country Code,User1 Mobile,User1 Address Line1,User1 Address Line2,User1 City,User1 Country,User1 State,User1 Postal Code,User2 First Name,User2 Middle Name,User2 Last Name,User2 Gender (M/F/O),User2 DOB (dd-MMM-yyyy),User2 Login Name,User2 Email,User2 Mobile Country Code,User2 Mobile,User2 Address Line1,User2 Address Line2,User2 City,User2 Country,User2 State,User2 Postal Code';
                           	
            	//csv = 'test, test'
    			/*var uri = 'data:text/csv;charset=UTF-8,' + escape(csvstring);
    			var link = document.createElement("a");
    			link.href = uri;
    			
    			link.style = 'hidden';
    			link.download = uploadType + '.csv';*/
    			
    			var base64data = "base64," + $.base64.encode(csvstring);
                var blob = new Blob([csvstring], { type: "application/" + "csv" + ';' + base64data })
                window.saveAs(blob, uploadType + "."  + "csv");
//    			document.body.appendChild(link);
//    			link.click();
//    			document.body.removeChild(link);
			}
			
			if(uploadType == 'BranchImport'){
				var csvstring = 'Bank Name,Branch Name,Branch IFSC Or Swift Code,Primary Contact Name,Primary Email,Primary Mobile Country Code,Primary Mobile,Branch Customer Care No,Address1,Address2,City,Country,State,Postal Code,Is User1 Required?(TRUE/FALSE),User1 First Name,User1 Middle Name,User1 Last Name,User1 Gender (M/F/O),User1 DOB (dd-MMM-yyyy),User1 Login Name,User1 Email,User1 Mobile Country Code,User1 Mobile,User1 Address Line1,User1 Address Line2,User1 City,User1 Country,User1 State,User1 Postal Code,Is User2 Required?(TRUE/FALSE),User2 First Name,User2 Middle Name,User2 Last Name,User2 Gender (M/F/O),User2 DOB (dd-MMM-yyyy),User2 Login Name,User2 Email,User2 Mobile Country Code,User2 Mobile,User2 Address Line1,User2 Address Line2,User2 City,User2 Country,User2 State,User2 Postal Code';
                         	
            	//csv = 'test, test'
    			/*var uri = 'data:text/csv;charset=UTF-8,' + escape(csvstring);
    			
    			var link = document.createElement("a");
    			link.href = uri;
    			
    			link.style = 'hidden';
    			link.download = uploadType + '.csv';
    			
    			document.body.appendChild(link);
    			link.click();
    			document.body.removeChild(link);*/
    			var base64data = "base64," + $.base64.encode(csvstring);
                var blob = new Blob([csvstring], { type: "application/" + "csv" + ';' + base64data })
                window.saveAs(blob, uploadType + "."  + "csv");
			}
			
			if(uploadType == 'MarkupImport'){
				var csvstring = 'Branch Name,Cust ID,Markup Type,Markup Value,Tax on Markup (%),RO/ED Applicability';
                         	
            	//csv = 'test, test'
    			/*var uri = 'data:text/csv;charset=UTF-8,' + escape(csvstring);
    			
    			var link = document.createElement("a");
    			link.href = uri;
    			
    			link.style = 'hidden';
    			link.download = uploadType + '.csv';
    			
    			document.body.appendChild(link);
    			link.click();
    			document.body.removeChild(link);*/
    			var base64data = "base64," + $.base64.encode(csvstring);
                var blob = new Blob([csvstring], { type: "application/" + "csv" + ';' + base64data })
                window.saveAs(blob, uploadType + "."  + "csv");
			}
			if(uploadType == 'LimitSettingsImport'){
				var csvstring = 'Branch Name,Customer ID,Operation Type (Add/Edit/Reset To All/Reset To Spot/Reset To Forward),Limit to be set for (SpotWindow/Forward/All),' +
					'Limit Type (Global Gross/Gross Buy/Gross Sell/Net),Limit Currency Code (USD/INR),CASH Limit Amount,TOM Limit Amount,SPOT Limit Amount,Upto Spot Limit Amount,'+ 
					'FORWARD Limit Amount, ALL Instr. Limit Amount,Limit on Tenors Type (Not Applicable/Maximum Tenor/Maximum Value Date),Maximum Tenor Type (Days/Months),'+ 
					'Maximum Tenor (No of days/months),Maximum Value Date(dd-MMM-yyyy),Effective To date(dd-MMM-yyyy),Limit Reinstatement Method (Not Applicable/Daily/Settled Trades),Remarks';
				
            	//csv = 'test, test'
    			/*var uri = 'data:text/csv;charset=UTF-8,' + escape(csvstring);
    			
    			var link = document.createElement("a");
    			link.href = uri;
    			
    			link.style = 'hidden';
    			link.download = uploadType + '.csv';
    			
    			document.body.appendChild(link);
    			link.click();
    			document.body.removeChild(link);*/
    			var base64data = "base64," + $.base64.encode(csvstring);
                var blob = new Blob([csvstring], { type: "application/" + "csv" + ';' + base64data })
                window.saveAs(blob, uploadType + "."  + "csv");
			}
			if(uploadType == 'AMCImport'){
				var csvstring = 'Bank Name, AMC';
                         	
            	//csv = 'test, test'
    			/*var uri = 'data:text/csv;charset=UTF-8,' + escape(csvstring);
    			
    			var link = document.createElement("a");
    			link.href = uri;
    			
    			link.style = 'hidden';
    			link.download = uploadType + '.csv';
    			
    			document.body.appendChild(link);
    			link.click();
    			document.body.removeChild(link);*/
    			var base64data = "base64," + $.base64.encode(csvstring);
                var blob = new Blob([csvstring], { type: "application/" + "csv" + ';' + base64data })
                window.saveAs(blob, uploadType + "."  + "csv");
			}
			if(uploadType == 'OneTimeRegistrationChargesImport'){
				var csvstring = 'Customer Type, Charges';
                         	
            	//csv = 'test, test'
    			/*var uri = 'data:text/csv;charset=UTF-8,' + escape(csvstring);
    			
    			var link = document.createElement("a");
    			link.href = uri;
    			
    			link.style = 'hidden';
    			link.download = uploadType + '.csv';
    			
    			document.body.appendChild(link);
    			link.click();
    			document.body.removeChild(link);*/
    			var base64data = "base64," + $.base64.encode(csvstring);
                var blob = new Blob([csvstring], { type: "application/" + "csv" + ';' + base64data })
                window.saveAs(blob, uploadType + "."  + "csv");
			}
			if(uploadType == 'GSTSettingsImport'){
				var csvstring = 'State, CGST(in %), SGST(in %), IGST(in %), UTGST(in %)';
                         	
            	//csv = 'test, test'
    			/*var uri = 'data:text/csv;charset=UTF-8,' + escape(csvstring);
    			
    			var link = document.createElement("a");
    			link.href = uri;
    			
    			link.style = 'hidden';
    			link.download = uploadType + '.csv';
    			
    			document.body.appendChild(link);
    			link.click();
    			document.body.removeChild(link);*/
    			var base64data = "base64," + $.base64.encode(csvstring);
                var blob = new Blob([csvstring], { type: "application/" + "csv" + ';' + base64data })
                window.saveAs(blob, uploadType + "."  + "csv");
			}
			
			
			if(uploadType == 'TransactionChargesImport'){
				var csvstring = 'Customer Type, Cumulative Amount From, Cumulative Amount To, Charges %';
                         	
            	//csv = 'test, test'
    			/*var uri = 'data:text/csv;charset=UTF-8,' + escape(csvstring);
    			
    			var link = document.createElement("a");
    			link.href = uri;
    			
    			link.style = 'hidden';
    			link.download = uploadType + '.csv';
    			
    			document.body.appendChild(link);
    			link.click();
    			document.body.removeChild(link);*/
    			var base64data = "base64," + $.base64.encode(csvstring);
                var blob = new Blob([csvstring], { type: "application/" + "csv" + ';' + base64data })
                window.saveAs(blob, uploadType + "."  + "csv");
			}
			
			if(uploadType == 'SwapQuotesImport'){
				
				/*var csvstring = 'Instrument,Settlement Date,Bid Spread,Offer Spread';
    			var base64data = "base64," + $.base64.encode(csvstring);
                var blob = new Blob([csvstring], { type: "application/" + "csv" + ';' + base64data })
                window.saveAs(blob, uploadType + "."  + "csv");*/
			}
			
		}

		function onImportSuccess(result) {
			if(uploadTypeName == 'HomeBranchImport'){
				$rootScope.homebranchUploadInProgress = false;
				MessageBox.closeMessageBox("autocloseImport");
			}
				MessageBox.showMessageBox("Alert", " File has been uploaded successfully. ", "Ok");
			//$state.go($state.current, {}, {});
			
			
		}

		function onImportFailure(result) {
			if(uploadTypeName == 'HomeBranchImport'){
				$rootScope.homebranchUploadInProgress = false;
				MessageBox.closeMessageBox("autocloseImport");
			}
			console.log(result);
			if(result.data!="") {
        		exportException(result.data, true, uploadTypeName + '_error');
        	}else{
                MessageBox.showMessageBox("Alert",uploadTypeName + " - Invalid File", "Ok");    
        	}
			//$state.go($state.current, {}, {});
		}

		function exportException(JSONData, showHeader, fileName) {

			if (JSONData != "") {
				var arrData = typeof (JSONData) != 'object' ? JSON
						.parse(JSONData) : JSONData;
						var out = arrData[arrData.length-1];

				
						if (out == "failure") {
					MessageBox.showMessageBox("Alert",
							"File upload failed! Please check the error log. ",
							"Ok");
					generateFile(arrData,fileName);
				} 
					else if(out=="invalid"){
						MessageBox.showMessageBox("Alert",
								uploadTypeName + " - Invalid file",
								"Ok");
					}
										
				
					else {
					MessageBox
							.showMessageBox(
									"Alert",
									"Partial success! Please check the error log for the failed records.  ",
									"Ok");
					generateFile(arrData,fileName);
				}

				
			}

		}
		
		function generateFile(arrData,fileName){
			var csv = "";

			for (var i = 0; i <= arrData.length-2; i++) {

				var row = "";

				
				
				row += arrData[i];
				
				/*for ( var index in arrData[i]) {
					
				}
*/
				row = row.slice(0, row.length - 1);

				csv += row + '\r\n';

			}
			
			console.log(csv);

			if (csv != '') {
				//csv = 'test, test'
				/*var uri = 'data:text/csv;charset=UTF-8,' + escape(csv);

				var link = document.createElement("a");
				link.href = uri;

				link.style = 'hidden';
				link.download = fileName + '.csv';

				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);*/
    			//var base64data = "base64," + $.base64.encode(csv);
                //var blob = new Blob([csv], { type: "application/" + "csv" + ';' + base64data })
				var blob = new Blob([csv], { type: "application/" + "csv" })
                window.saveAs(blob, fileName + "."  + "csv");
			}
		}

	}
})();
