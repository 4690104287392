(function() {
	'use strict';

	angular
	.module('gatewayApp')
	.controller('CustomerTACUploadByBankController', CustomerTACUploadByBankController);

	CustomerTACUploadByBankController.$inject = ['$scope', '$state', '$rootScope', 'GlobalValues', 'workflowSharedService','CustomerTACUploadByBank','entity','DateUtils','Workflow','FileUtil','AlertService','$filter','systemSettings','TacViewForEntitiesUtility','entityConstants','ScreenLog','globalValue','TACViewForEntitiesService','configurationSetting','$uibModalInstance'];

	function CustomerTACUploadByBankController($scope, $state, $rootScope, GlobalValues, workflowSharedService,CustomerTACUploadByBank,entity,DateUtils,Workflow,FileUtil,AlertService,$filter,systemSettings,TacViewForEntitiesUtility,entityConstants,ScreenLog,globalValue,TACViewForEntitiesService,configurationSetting,$uibModalInstance) {
		var vm = this;
		vm.bankForCustomerTacDTO=entity;   

		vm.clear=clear;
		vm.save=save;
		vm.clicked=false;
		$scope.showButton = false;
		$scope.showApproveRejectButton=false;
		$scope.showDiscardButton = false;
		$scope.showResubmitButton=false;
		vm.globalValues = GlobalValues;
		vm.openClearcorpTac = TacViewForEntitiesUtility.onGetCcilTacForBank;
        vm.messageBoxName = "customerTACUpload";
		vm.viewMode=false;
		var minFileSizeinBytes = 0;
		var maxFileSizeinBytes = 500;
		vm.maximumFileSizeinKB = (configurationSetting != null && configurationSetting != undefined && configurationSetting.maximumFileSizeTermsAndConditionsUpload != null && configurationSetting.maximumFileSizeTermsAndConditionsUpload != undefined && configurationSetting.maximumFileSizeTermsAndConditionsUpload != '') ? parseInt(configurationSetting.maximumFileSizeTermsAndConditionsUpload) : 500 ;
		vm.minimumFileSizeInKB = 0;
		vm.screenName = entityConstants.ENTITY_SHORT_NAME.CUSTOMER_TERMS_AND_CONDITIONS_UPLOAD;
		vm.configSettings = globalValue;
		vm.checkTACFile = false;

		vm.maxFileNameLength = (vm.configSettings.uploadedFileNameLength != null && vm.configSettings.uploadedFileNameLength != undefined && vm.configSettings.uploadedFileNameLength != '') ?vm.configSettings.uploadedFileNameLength : 150 ;
		
		if( vm.minimumFileSizeInKB != undefined &&  vm.minimumFileSizeInKB !=null &&  vm.minimumFileSizeInKB  !=''){
			minFileSizeinBytes = vm.minimumFileSizeInKB*1024;
		}
		if( vm.maximumFileSizeinKB != undefined &&  vm.maximumFileSizeinKB !=null &&  vm.maximumFileSizeinKB  !=''){
			
			maxFileSizeinBytes = vm.maximumFileSizeinKB*1024;
			if(vm.maximumFileSizeinKB == 1024){
				vm.showInKB = false;
				vm.tacFileUploadSize = 1;
			}else{
				vm.showInKB = true;
				vm.tacFileUploadSize = vm.maximumFileSizeinKB;
			}
		}
		
		var Extensions = "pdf";
		vm.minDate = new Date();
		vm.minDate.setDate(new Date().getDate() + 1);

		if(systemSettings != null && systemSettings != undefined){
			vm.businessDate  = DateUtils.convertLocalDateFromServer(systemSettings.businessDate);
			vm.businessDatePlusOne =  moment(vm.businessDate).add(1,'days').toDate();
			vm.minDate = angular.copy(vm.businessDatePlusOne);
		}
		
		$scope.showButton = false;
		vm.orgId = vm.globalValues.getOrgId();



		vm.bankForCustomerTacDTO.enforcingDate = vm.minDate;
		$scope.showButton = true;
		$scope.showApproveRejectButton = false;
		$scope.showDiscardButton = false;
		$scope.showResubmitButton=false;
		vm.viewMode=false;
		
		vm.tacMasterId = 0;
		ScreenLog.screenLog(vm.screenName,vm.tacMasterId, entityConstants.ENTITY_ACTION_TYPE.EDIT);
		vm.bankForCustomerTacDTO.bank = vm.globalValues.getOrganisation();
		CustomerTACUploadByBank.getTermsConditionDetailsTACMaster({organisationId:GlobalValues.getOrgId()},function(result) {
			if(result!=null){
			vm.bankForCustomerTacDTO.tacMaster =result[0] ;
		}
		});

		function base64ToArrayBuffer(base64) {
			var binary_string =  window.atob(base64);
			var len = binary_string.length;
			var bytes = new Uint8Array( len );
			for (var i = 0; i < len; i++)        {
				bytes[i] = binary_string.charCodeAt(i);
			}
			return bytes.buffer;
		}

		vm.openTACPreview = openTACPreview;
		function openTACPreview(pdfContent){
			var y = window.outerHeight / 2 + window.screenY - ( 700 / 2);
			var x = window.outerWidth / 2 + window.screenX - ( 700 / 2);
			var contentByteArray = base64ToArrayBuffer(pdfContent);
			var file = new Blob([contentByteArray], {type: 'application/pdf'});
			$scope.fileURL = URL.createObjectURL(file);
			if(window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(file, "TermsAndConditions.pdf");
			}
			else {
				window.open($scope.fileURL, "_blank", "width=700,height=700,"+"top="+y+", left="+x);  

			}
		}



		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		function save () {   
			//check if the terms and condition is not null before saving

			if(moment(vm.minDate).isAfter(DateUtils.convertLocalDateToServer(vm.bankForCustomerTacDTO.enforcingDate))){
				vm.clicked = false;
				AlertService.error($filter('translate')('gatewayApp.customerTacUploadByBank.enforcingDateGreater'));
				
			}
			else{
				if(vm.termsCondition != null){
					var fd1 = new FormData();				
					var enforcingDate = DateUtils.convertLocalDateToServer(vm.bankForCustomerTacDTO.enforcingDate);
					fd1.append('enforcingDate', enforcingDate);
					fd1.append('dto', JSON.stringify(vm.bankForCustomerTacDTO));
					fd1.append('file', vm.termsCondition);
					CustomerTACUploadByBank.update(fd1, onSaveSuccess, onSaveError);
				}
			}
		}
		
		function resetValues(){
			vm.clicked = false;

				vm.bankForCustomerTacDTO.enforcingDate = vm.minDate;
				vm.inputTermsCondition=null;
				vm.termsCondition = null;

		}


		function onSaveSuccess (result) {
			$uibModalInstance.close(result);  
		}


		function onSaveError () {

			vm.clicked = false;
		}


		//validate terms and condition 
		$scope.$watch('vm.termsCondition', function(value){

			if(value!= undefined && value!=null){
				if(!FileUtil.isFileSizeValid(value.size ,minFileSizeinBytes , maxFileSizeinBytes  )){
					vm.invalidTermsConditionSize = true;
					if(vm.showInKB == true){
						vm.invalidTermsConditionSixeText = "Maximum of " + vm.tacFileUploadSize +" KB file size is allowed";
					}else{
						vm.invalidTermsConditionSixeText = "Maximum of " + vm.tacFileUploadSize +" MB file size is allowed";
					}
					vm.invalidTermsCondition = false;
					vm.invalidTermsConditionName = false;
					vm.termsCondition=null;
					angular.element("input[type='file']").val(null);

				}else{
					vm.inputTermsCondition=vm.termsCondition.name;
					vm.invalidTermsConditionSize = false;
					vm.invalidTermsCondition = false;
					vm.invalidTermsConditionName = false;
					vm.fileExtension = vm.inputTermsCondition.substring(vm.termsCondition.name.lastIndexOf(".") + 1);
					var fileNameWithoutExtension  =  vm.inputTermsCondition.substring(0,vm.termsCondition.name.lastIndexOf("."));
					//check for extension
					if(!(Extensions == (vm.fileExtension.toLowerCase()))) {
						angular.element("input[type='file']").val(null);
						vm.termsCondition=null;
						vm.inputTermsCondition=null;
						vm.invalidTermsCondition = true;
						vm.invalidTermsConditionSize = false;
						vm.invalidTermsConditionName = false;
						vm.invalidTermsConditionText = "Accepted file format .pdf";
					}
					else if(!FileUtil.isFileNameValid(fileNameWithoutExtension,vm.maxFileNameLength )){
	    				 angular.element("input[type='file']").val(null);
	    				 vm.termsCondition=null;
				         vm.inputTermsCondition=null;
				         vm.invalidTermsCondition = false;
				         vm.invalidTermsConditionSize = false;
				         vm.invalidTermsConditionName = true;
				         vm.invalidTermsConditionNameText = "Uploaded pdf file name should not exceed " + vm.maxFileNameLength+ " charaters.";
				         
	    			 }

					else{ // check for malicious file with correct extension
						var reader = new FileReader();
						reader.readAsArrayBuffer(value.slice(0,4));
						reader.onloadend = function(e){
							if (e.target.readyState === FileReader.DONE) {
								var arrayBuffer = e.target.result;
								var bytes = new Uint8Array( arrayBuffer );
								if(!FileUtil.checkTermsConditionExtension(bytes)){
									angular.element("input[type='file']").val(null);
									vm.termsCondition=null;
									vm.inputTermsCondition=null;
									vm.invalidTermsCondition = true;
									vm.invalidTermsConditionSize = false;
									vm.invalidTermsConditionName = false;
									vm.invalidTermsConditionText = "Accepted file format .pdf";
								}
								else{
									vm.invalidTermsConditionSize = false;
									vm.invalidTermsCondition =false;
									vm.invalidTermsConditionName = false;


								}
								$scope.$digest();
							}
						}; 
					}
				}
			}
			else{
				angular.element("input[type='file']").val(null);
				
				vm.inputTermsCondition=null;
				
			}
		});
		
	        

	}   
})();
