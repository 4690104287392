(function() {
    'use strict';

    angular
        .module('gatewayApp')
         .service("workflowSharedService", function () {        	
        	this.viewFrom= 'limitUtilisationSplitup'
        })
        
        .controller('LimitUtilisationSplitupController', LimitUtilisationSplitupController);

    LimitUtilisationSplitupController.$inject = ['$scope', 'LimitUtilisationSplitup', 'GlobalValues', 'workflowSharedService', 'ScreenLog','entityConstants', 'DefaultMarket'];

    function LimitUtilisationSplitupController($scope, LimitUtilisationSplitup , GlobalValues, workflowSharedService, ScreenLog,entityConstants, DefaultMarket) {
        var vm = this;
        vm.entityConstants = entityConstants;
        vm.screenName = entityConstants.ENTITY_SHORT_NAME.LIMIT_UTILISATION_SPLITUP;
        $scope.noOfRecordsPerPage = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.page = 1;
        $scope.noOfRecordsPerPageOrder = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.pageOrder = 1;
        $scope.noOfRecordsPerPageTrade = GlobalValues.getNoOfRecordsPerPageInPagination();
        $scope.pageTrade = 1;
        vm.globalValues=GlobalValues;
        vm.screenLog = ScreenLog;
        vm.noOfDecimalsForRate = vm.globalValues.getNumberOfDecimalsForRate();
        vm.noOfDecimalsForAmount = vm.globalValues.getNumberOfDecimalsForAmount();
        vm.clicked=false;
        
        DefaultMarket.marketInstruments(function(result){
            vm.instrumentTypes = result;
        });
        vm.entityOwnerType = vm.globalValues.getEntityOwnerType().shortName;
        vm.orgId = vm.globalValues.getOrgId();
        vm.exportToFile = exportToFile;
        vm.filterData = {};
        vm.limitUtilisationSplitupHeaderList = [];
        vm.limitUtilSplitupTradeList=[];
        vm.limitUtilSplitupOrderList=[];
        vm.valid=false;
        
        vm.mimeType= '';  	  
  	  	vm.baseString =   'Limit_Utilisation_Splitup_Report_'+ moment().format( 'DD_MMM_YYYY_HH_mm');   	
        
        if(vm.entityOwnerType=='MultiUserCustomer' || vm.entityOwnerType=='SingleUserCustomer'){
            vm.customer = vm.globalValues.getCustomer();
        }
        if(vm.entityOwnerType=='Bank' || vm.entityOwnerType=='Branch'){
        	vm.bank = vm.globalValues.getBank();
        }
        if(vm.entityOwnerType=='Branch'){
        	vm.branch = vm.globalValues.getBranch();
        }
        
        vm.getLimitUtilisationSplitupHeaderList = getLimitUtilisationSplitupHeaderList;
        vm.getLimitUtilSplitupDetails = getLimitUtilSplitupDetails;
        vm.getBuyingPower = getBuyingPower;
        vm.getSellingPower = getSellingPower;
        vm.getExposure = getExposure;

        vm.ExpandOrCollapseLimitUtilisationSplitup=true;
        
        workflowSharedService.viewFrom = "transactionCharges";
       
        
        logScreenAccess();
        
        if (vm.entityOwnerType == 'Clearcorp'){
            vm.disableBank = false;
            vm.disableBranch = false;
            vm.disableCustomer = false;
        }
        else if (vm.entityOwnerType == 'Bank'){
              vm.disableBank = true;
              vm.disableBranch = false;
              vm.disableCustomer = false;
        }
        else if (vm.entityOwnerType == 'Branch'){
              vm.disableBank = true;
              vm.disableBranch = true;
              vm.disableCustomer = false;
        }
        else if (vm.entityOwnerType == 'MultiUserCustomer' || vm.entityOwnerType == 'SingleUserCustomer'){
              vm.disableBank = false;
              vm.disableBranch = true;
              vm.disableCustomer = true;
        }

       function getLimitUtilisationSplitupHeaderList() {
    	   
    	   vm.bankId = 0;
    	   vm.branchId = 0;
    	   vm.customerId= 0;
           vm.instrumentId = 0;
    	   vm.selectedLimitUtilisationSplitupHeader = {};
           vm.limitUtilisationSplitupHeaderList=[];
           vm.limitUtilSplitupTradeList=[];
           vm.limitUtilSplitupOrderList=[];
    	   vm.showTradeDetails = false;
           vm.showOrderDetails = false;
    	   
           if (vm.entityOwnerType == 'Bank' || vm.entityOwnerType == 'Branch'){
               vm.bankId = vm.globalValues.getOrganisation().id;
           }else if(vm.bank!=null && vm.bank!=undefined){
               if(vm.entityOwnerType == 'Clearcorp' || vm.entityOwnerType == 'MultiUserCustomer' || vm.entityOwnerType == 'SingleUserCustomer'){
                      vm.bankId = vm.bank.id;
               }
           }
           
           if(vm.entityOwnerType == 'Branch'){
               vm.branchId = vm.globalValues.getBranch().id;
           }else  if(vm.branch!=null && vm.branch!=undefined){
               if(vm.entityOwnerType=='Clearcorp' || vm.entityOwnerType=='Bank'){
                   vm.branchId = vm.branch.id;
               }
           }
           
           if(vm.entityOwnerType=='MultiUserCustomer' || vm.entityOwnerType=='SingleUserCustomer'){
               vm.customerId = vm.globalValues.getCustomerId();
           } else  if(vm.customer!=null && vm.customer!=undefined){
               if(vm.entityOwnerType!='MultiUserCustomer' && vm.entityOwnerType!='SingleUserCustomer'){
                   vm.customerId = vm.customer.id;
               }
           }

           if(vm.instrument!=null && vm.instrument!=undefined){
                vm.instrumentId = vm.instrument.id;
           }
           
           vm.valid = true;
           copyFilterData();
           LimitUtilisationSplitup.getLimitUtilisationSplitupHeader({"bankId": vm.bankId, "branchId":vm.branchId , "customerId" : vm.customerId ,
           "instrumentId": vm.instrumentId},function(result) {  
               vm.limitUtilisationSplitupHeaderList = result;
               vm.clicked=false;
            });        	
  
        }      
       
       function logScreenAccess(){
       	ScreenLog.screenLog( vm.screenName,0, entityConstants.ENTITY_ACTION_TYPE.ACCESS);
       }
       
       function getLimitUtilSplitupDetails(limitUtilisationSplitupHeader){
    	   vm.limitUtilSplitupTradeList=[];
           vm.limitUtilSplitupOrderList=[];
    	   vm.selectedLimitUtilisationSplitupHeader = limitUtilisationSplitupHeader; 
           //set limit splitup trade details
    	   vm.limitUtilSplitupTradeList=vm.selectedLimitUtilisationSplitupHeader.limitUtilSplitupTradeDetailsList;
           //if(vm.limitUtilSplitupTradeList!=null && vm.limitUtilSplitupTradeList!=undefined && vm.limitUtilSplitupTradeList.length>0){
                vm.showTradeDetails = true;
           //}

            //set limit splitup order details
            vm.limitUtilSplitupOrderList=vm.selectedLimitUtilisationSplitupHeader.limitUtilSplitupOrderDetailsList;
            //if(vm.limitUtilSplitupOrderList!=null && vm.limitUtilSplitupOrderList!=undefined && vm.limitUtilSplitupOrderList.length>0){
                 vm.showOrderDetails = true;
            //}
         }

      function exportToFile(fileType){
    	  vm.baseString =   'Limit_Utilisation_Splitup_Report_'+ moment().format( 'DD_MMM_YYYY_HH_mm_ss');   	
    	  if(vm.limitUtilisationSplitupHeaderList !=null &&  vm.limitUtilisationSplitupHeaderList!=undefined && vm.limitUtilisationSplitupHeaderList.length>0){
    		  if(fileType == 'csv'){
    			 vm.mimeType = 'text/csv'
    			 vm.baseString = vm.baseString+ ".csv";  
    			 LimitUtilisationSplitup.exportLimitUtilisationSplitupCsv({bankId:  vm.filterData.bankId, branchId:  vm.filterData.branchId, 
        			customerId:  vm.filterData.customerId,  instrumentId:  vm.filterData.instrumentId}, vm.limitUtilisationSplitupHeaderList, processResponse )
    		  }else if(fileType == 'excel'){
    			  vm.mimeType = 'application/vnd.ms-excel';
    			  vm.baseString = vm.baseString+ ".xls";  
    			  LimitUtilisationSplitup.exportLimitUtilisationSplitupExcel({bankId:  vm.filterData.bankId, branchId:  vm.filterData.branchId, 
        			customerId:  vm.filterData.customerId,  instrumentId:  vm.filterData.instrumentId}, vm.limitUtilisationSplitupHeaderList, processResponse );
    		  }
    	  }
      }

      function processResponse(result){
    	  var fileData = null;
		  fileData = result.data;
		  var link = document.createElement("a");   
		  var bytes = new Uint8Array( fileData );   // convert uint8 to binary array from arraybuffer
		  
		  
	      var blob = new Blob([bytes], {type: vm.mimeType});
	      if(window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob, vm.baseString );
	      }else{
	    	  link.href =  window.URL.createObjectURL(blob);      
	    	  link.download = vm.baseString;
	    	  document.body.appendChild(link);
	    	  link.click();
	    	  document.body.removeChild(link);
	      }
	  }

        function copyFilterData(){
        	if(vm.bank!=null && vm.bank !=undefined){
        		vm.filterData.bankId = angular.copy(vm.bank.id);
        	}else{
        		vm.filterData.bankId = 0;
        	}
        	if(vm.branch!=null && vm.branch!=undefined){
        		vm.filterData.branchId = angular.copy(vm.branch.id);
        	}else{
        		vm.filterData.branchId = 0;
        	}if(vm.customer!=null && vm.customer!=undefined){
        		vm.filterData.customerId = angular.copy(vm.customer.id);
        	}else{
        		vm.filterData.customerId = 0;
        	}
        	if(vm.instrument!=null && vm.instrument!=undefined){
        		vm.filterData.instrumentId = angular.copy(vm.instrument.id);
        	}else{
        		vm.filterData.instrumentId = 0;
        	}
        }

      function getBuyingPower(limit){
        var buyingPower = 0;
        var totalBuy = limit.pendingBuyOrder + limit.buyTrade + limit.previousDayBuyTrade;
        var totalSell = limit.pendingSellOrder + limit.sellTrade + limit.previousDaySellTrade;
        if(limit.limitType=='GROSSSELL'){
            buyingPower = 0;
        }else if(limit.limitType=='GROSSBUY'){
            buyingPower = limit.totalAllocatedInUSD - (totalBuy);
        }else if(limit.limitType=='GLOBALGROSS'){
            buyingPower = limit.totalAllocatedInUSD - (totalBuy + totalSell);
        }else if(limit.limitType=='NET'){
            totalBuy = limit.pendingBuyOrder+((limit.buyTrade + limit.previousDayBuyTrade) - (limit.sellTrade + limit.previousDaySellTrade));
            totalSell = limit.pendingSellOrder+((limit.sellTrade + limit.previousDaySellTrade) - (limit.buyTrade  + limit.previousDayBuyTrade));
            var xVals = [];
            xVals.push(totalBuy);
            xVals.push(totalSell);
            buyingPower = limit.totalAllocatedInUSD - totalBuy;
        }
        if (buyingPower < 0) {
            buyingPower = 0; // If negative, set it to 0
          }
        return buyingPower;
    }
    
    function getExposure(limit){
        var exposure = 0;
        var totalBuy = 0;
        var totalSell = 0;
        if(limit.limitType!='NET'){
            totalBuy = limit.pendingBuyOrder + limit.buyTrade + limit.previousDayBuyTrade;
            totalSell = limit.pendingSellOrder + limit.sellTrade + limit.previousDaySellTrade;
            exposure = (limit.pendingBuyOrder + limit.buyTrade + limit.previousDayBuyTrade + limit.pendingSellOrder + limit.sellTrade + limit.previousDaySellTrade) * 100/limit.totalAllocatedInUSD;
        }else{
            totalBuy = limit.pendingBuyOrder+((limit.buyTrade + limit.previousDayBuyTrade) - (limit.sellTrade + limit.previousDaySellTrade));
            totalSell = limit.pendingSellOrder+((limit.sellTrade + limit.previousDaySellTrade) - (limit.buyTrade + limit.previousDayBuyTrade));

            var xVals = [];
            xVals.push(totalBuy);
            xVals.push(totalSell);
            exposure =  Math.max.apply(Math, xVals) * 100/limit.totalAllocatedInUSD;
        }
        return exposure;
    }
    
    function getSellingPower(limit){
        var sellingPower = 0;
        var totalBuy = limit.pendingBuyOrder + (limit.buyTrade + limit.previousDayBuyTrade);
        var totalSell = limit.pendingSellOrder + (limit.sellTrade + limit.previousDaySellTrade);
        if(limit.limitType=='GROSSSELL'){
            sellingPower = limit.totalAllocatedInUSD - (totalSell);
        }else if(limit.limitType=='GROSSBUY'){
            sellingPower = 0;
        }else if(limit.limitType=='GLOBALGROSS'){
            sellingPower = limit.totalAllocatedInUSD - (totalBuy + totalSell);
        }else if(limit.limitType=='NET'){
            totalBuy = limit.pendingBuyOrder+((limit.buyTrade + limit.previousDayBuyTrade) - (limit.sellTrade + limit.previousDaySellTrade));
            totalSell = limit.pendingSellOrder+((limit.sellTrade + limit.previousDaySellTrade) - (limit.buyTrade + limit.previousDayBuyTrade));
            var xVals = [];
            xVals.push(totalBuy);
            xVals.push(totalSell);
            sellingPower = limit.totalAllocatedInUSD - totalSell;
        }
        if (sellingPower < 0) {
            sellingPower = 0; // If negative, set it to 0
          }
        return sellingPower;
    }

    }
})();
